import request from '@/utils/request'


// 查询举报列表
export function listReport(query) {
  return request({
    url: '/biz/report/list',
    method: 'get',
    params: query
  })
}

// 查询举报分页
export function pageReport(query) {
  return request({
    url: '/biz/report/page',
    method: 'get',
    params: query
  })
}

// 查询举报详细
export function getReport(data) {
  return request({
    url: '/biz/report/detail',
    method: 'get',
    params: data
  })
}

// 新增举报
export function addReport(data) {
  return request({
    url: '/biz/report/add',
    method: 'post',
    data: data
  })
}

// 修改举报
export function updateReport(data) {
  return request({
    url: '/biz/report/edit',
    method: 'post',
    data: data
  })
}

// 删除举报
export function delReport(data) {
  return request({
    url: '/biz/report/delete',
    method: 'post',
    data: data
  })
}
